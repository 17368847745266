/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import i18n from 'i18next';

// Material UI
import { Delete, Publish } from '@material-ui/icons';

// Store
import RoleStore from '@Stores/RoleStore';
import TermsAndConditionsStore from '@Stores/TermsAndConditionsStore';

// Utils
import { handleRowClick } from '@Utils';
import { formatDate } from '@Utils/formatting';

const {
  publishTermsAndConditions,
  getTermsAndConditionsList,
  clearTermsAndConditionsList,
  deleteTermsAndConditionsDraft
} = TermsAndConditionsStore;

const clearAndGetTermsList = () => {
  clearTermsAndConditionsList();
  getTermsAndConditionsList(RoleStore.role.id);
};

export const handlePublish = ({ rowId }) =>
  publishTermsAndConditions(RoleStore.role.id, rowId).then(
    clearAndGetTermsList
  );

export const handleDelete = ({ rowId }) =>
  deleteTermsAndConditionsDraft(rowId).then(clearAndGetTermsList);

export const tableConfig = {
  defaultOrder: 'desc',
  defaultOrderBy: 'variant',
  hasActions: true,
  rowIdKey: 'id',
  // `/roles/${RoleStore.role.id}/termsAndConditions/${termsId}/view`
  handleRowClick: handleRowClick(['/roles/32/termsAndConditions/', '/view']),
  headings: [
    i18n.t('mwAdmin.roles.version'),
    i18n.t('common.status'),
    i18n.t('mwAdmin.roles.published'),
    i18n.t('mwAdmin.roles.expired')
  ],
  // ! TODO: input valid values when integrate with backend
  rowCells: [
    'version',
    'status',
    { key: 'published', cb: formatDate },
    { key: 'expired', cb: formatDate }
  ],
  actionsConfig: {
    keyForActivation: 'status',
    isDisabled: value => value !== 'DRAFT',
    menuItems: [
      {
        name: i18n.t('common.publish'),
        icon: <Publish />,
        handleClick: handlePublish
      },
      {
        name: i18n.t('common.delete'),
        icon: <Delete />,
        handleClick: handleDelete
      }
    ]
  }
};
