/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

// Material UI
import { Grid, Typography, Button } from '@material-ui/core';

// Components
import { FormFieldsGenerator, RadioButtons } from '@CommonScene';

// Utils
import { useStore } from '@Utils/context';
import { compose } from '@Utils';

// Styles
import useStyles from './styles';

const RoleSettings = ({ handleNext, activeStep, goBack }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    RoleStore: { forms, joiningOption, setJoiningOption }
  } = useStore();

  const handleChange = event => {
    setJoiningOption(event.target.value);
  };

  const handleNextMemo = useCallback(() => handleNext(activeStep), [
    handleNext,
    activeStep
  ]);

  return (
    <Fragment>
      <Grid item className={classes.form}>
        <FormFieldsGenerator config={forms.roleSettings} />
        <Typography variant="subtitle1">Joining options</Typography>
        <RadioButtons
          activeValue={joiningOption}
          handleChange={handleChange}
          buttons={[
            {
              label: t('mwAdmin.roles.invitationOnly'),
              value: 'invitation',
              hint: t('mwAdmin.roles.invitationOnlyHint')
            },
            {
              label: t('mwAdmin.roles.publicAccess'),
              value: 'invitationOrPublic',
              hint: t('mwAdmin.roles.publicAccessHint')
            }
          ]}
        />
      </Grid>
      <Grid item className={classes.buttonsWrapper}>
        <Button id="back-btn" onClick={goBack}>
          {t('common.cancel')}
        </Button>
        <Button
          id="next-btn"
          variant="contained"
          disabled={!forms.roleSettings.meta.isValid}
          color="secondary"
          onClick={handleNextMemo}
          className={classes.button}
        >
          {t('common.next')}
        </Button>
      </Grid>
    </Fragment>
  );
};

RoleSettings.propTypes = {
  handleNext: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  activeStep: PropTypes.bool
};

export default compose(observer)(RoleSettings);
