/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import { observable, action } from 'mobx';

// Providers
import { CareProtocolProvider } from '@Providers';

// Stores
import BaseListStore from '@Stores/BaseListStore';
import CommonStore from '@Stores/CommonStore';

class TermsAndConditionsStore extends BaseListStore {
  @observable draftText = '';

  @observable draftId = null;

  @observable isTermsRequired = false;

  @observable termsAndConditionsList = [];

  @observable isLastPage = true;

  @observable termsAndConditionsItem = {};

  @action('termsAndConditionsStore => linkTermsAndConditionsDraftToRole')
  linkTermsAndConditionsDraftToRole = roleId => {
    CommonStore.setPending();
    return CareProtocolProvider.linkTermsAndConditionsDraftToRole(
      roleId,
      this.draftId
    )
      .catch()
      .finally(CommonStore.clearPending);
  };

  @action('termsAndConditionsStore => update draft text')
  updateTermsAndConditionsText = text => {
    this.draftText = text;
  };

  @action('termsAndConditionsStore => clear draft text')
  clearTermsAndConditionsText = () => {
    this.draftText = '';
  };

  @action('termsAndConditionsStore => change terms requirement')
  changeTermsRequirement = value => {
    this.isTermsRequired = value;
  };

  @action('termsAndConditionsStore => createTermsAndConditionsDraft')
  createTermsAndConditionsDraft = (roleId, withPublishing = false) => {
    CommonStore.setPending();
    return CareProtocolProvider.createTermsAndConditionsDraft({
      text: this.draftText
    })
      .then(
        action(
          'termsAndConditionsStore => handle response in createTermsAndConditionsDraft',
          response => {
            this.draftId = response.id;
            this.linkTermsAndConditionsDraftToRole(roleId).then(() => {
              if (withPublishing) {
                this.publishTermsAndConditions(roleId);
              }
            });
          }
        )
      )
      .catch()
      .finally(CommonStore.clearPending);
  };

  @action('termsAndConditionsStore => publishTermsAndConditions')
  publishTermsAndConditions = (roleId, draftId = this.draftId) => {
    CommonStore.setPending();
    return CareProtocolProvider.publishTermsAndConditions(roleId, draftId)
      .then(this.resetPage)
      .catch()
      .finally(CommonStore.clearPending);
  };

  @action('termsAndConditionsStore => getTermsAndConditionsList')
  getTermsAndConditionsList = roleId => {
    CommonStore.setPending();
    return CareProtocolProvider.getTermsAndConditionsList({
      roleId,
      page: this.page,
      pageSize: this.rowsPerPage
    })
      .then(
        action(
          'handle response in getTermsAndConditionsList',
          ({ content, last }) => {
            this.termsAndConditionsList = [
              ...this.termsAndConditionsList,
              ...content
            ];
            this.isLastPage = last;
            this.setPage(this.page + 1);
          }
        )
      )
      .catch()
      .finally(CommonStore.clearPending);
  };

  @action('termsAndConditionsStore => getTermsAndConditionsItem')
  getTermsAndConditionsItem = termId => {
    CommonStore.setPending();
    return CareProtocolProvider.getTermsAndConditionsItem(termId)
      .then(
        action('handle response in getTermsAndConditionsItem', response => {
          this.termsAndConditionsItem = response;
        })
      )
      .catch()
      .finally(CommonStore.clearPending);
  };

  @action('termsAndConditionsStore => clearTermsAndConditionsList')
  clearTermsAndConditionsList = () => {
    this.termsAndConditionsList = [];
  };

  @action('termsAndConditionsStore => deleteTermsAndConditionsDraft')
  deleteTermsAndConditionsDraft = termId => {
    CommonStore.setPending();
    return CareProtocolProvider.deleteTermsAndConditionsDraft(termId)
      .then()
      .catch()
      .finally(CommonStore.clearPending);
  };

  @action('termsAndConditionsStore => reset') reset = () => {
    this.resetPage();
    this.clearTermsAndConditionsList();
    this.isLastPage = true;
  };
}

export default new TermsAndConditionsStore();
