/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action } from 'mobx';

// Stores
import ValidationStore from '@Stores/ValidationStore';

export default class BaseListStore extends ValidationStore {
  @observable errors = null;

  @observable order = 'asc';

  @observable orderBy = '';

  @observable page = 0;

  @observable rowsPerPage = 25;

  @observable searchQuery = '';

  @action('BaseListStore => _handleServerError') _handleServerError = err => {
    this.errors = err.response && err.response.body && err.response.body.errors;
    throw err;
  };

  @action('BaseListStore => resetOrder') resetOrder = () => {
    this.order = 'asc';
  };

  @action('BaseListStore => _changeOrder') _changeOrder = (
    orderBy = this.orderBy
  ) => {
    if (this.orderBy !== orderBy) {
      this.orderBy = orderBy;
      // set default sorting if new column is clicked
      this.order = 'asc';
    } else if (this.order === 'asc') {
      this.order = 'desc';
    } else {
      this.order = 'asc';
    }
  };

  @action('BaseListStore => resetPage') resetPage = () => {
    this.page = 0;
  };

  @action('BaseListStore => setPage') setPage = page => {
    this.page = page;
  };

  @action('BaseListStore => clearQuery') clearQuery = () => {
    this.searchQuery = '';
  };

  @action('NetworkStore => setQuery') setQuery = query => {
    this.searchQuery = query;
    this._searchWithDebounce();
  };
}
