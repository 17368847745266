/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Tools
import { compose } from '@Utils';

// Components
import { MainContentWrapper } from '@CommonScene';
import RoleDetails from '@RolesScene/scenes/ViewRole/components/RoleDetails';
import TermsAndConditionsTable from '@RolesScene/scenes/common/TermsAndConditions/Table';
import CardAccessList from '@RolesScene/scenes/ViewRole/components/CardAccess';

export class ViewRole extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getRole: PropTypes.func.isRequired,
    getTermsAndConditionsList: PropTypes.func.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    removeRole: PropTypes.func.isRequired,
    DeckStore: PropTypes.object.isRequired,
    RoleStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.shape({
      setModalOptions: PropTypes.func
    }).isRequired,
    InvitationStore: PropTypes.object.isRequired,
    TermsAndConditionsStore: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { getTermsAndConditionsList, getRole } = this.props;

    const roleId = this.getCurrentRoleId();
    getRole(roleId).then(() => getTermsAndConditionsList(roleId));
  }

  componentWillUnmount() {
    const {
      DeckStore: { resetCheckedDecks },
      TermsAndConditionsStore: { reset },
      RoleStore: { resetRole }
    } = this.props;

    resetCheckedDecks();
    reset();
    resetRole();
  }

  getCurrentRoleId() {
    return this.props.match.params.role;
  }

  inviteUserToRole = () => {
    const { history, InvitationStore } = this.props;
    InvitationStore.setPredefinedRole(this.getCurrentRoleId());
    history.push('/invitations/create');
  };

  handleDeleteClick = () => {
    this.props.CommonStore.setModalOptions({
      modalName: 'ConfirmationAction',
      modalProps: {
        handleConfirmAction: this.handleDeleteConfirmation
      }
    });
  };

  handleDeleteConfirmation = () => {
    const { removeRole, redirectUrl } = this.props;

    // TODO: not worked - used old endpoint
    removeRole(this.getCurrentRoleId()).then(() => {
      this.props.history.replace(redirectUrl);
    });
  };

  getHeaderOptions() {
    const { t } = this.props;

    return {
      title: t('mwAdmin.roles.roleProfile')
    };
  }

  fetchMoreTermsAndConditionsData = () => {
    const { getTermsAndConditionsList } = this.props;
    getTermsAndConditionsList(this.getCurrentRoleId());
  };

  render() {
    const {
      RoleStore: { role },
      TermsAndConditionsStore: { termsAndConditionsList, isLastPage }
    } = this.props;

    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <RoleDetails
          roleId={this.getCurrentRoleId()}
          handleDeleteClick={this.handleDeleteClick}
          inviteUserToRole={this.inviteUserToRole}
          role={role}
        />

        {role.resources ? (
          <CardAccessList id="access-list" resources={role.resources} />
        ) : null}

        {role.joining_options && role.joining_options.enabled_terms ? (
          <TermsAndConditionsTable
            id="terms-table"
            hasActiveColumn
            tableData={termsAndConditionsList}
            last={isLastPage}
            fetchTableData={this.fetchMoreTermsAndConditionsData}
          />
        ) : null}
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject(
    'RoleStore',
    'DeckStore',
    'InvitationStore',
    'CommonStore',
    'TermsAndConditionsStore'
  ),
  withRouter,
  observer
)(ViewRole);
