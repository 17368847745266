/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useEffect, useCallback, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Material UI
import { Button, Grid } from '@material-ui/core';

// Components
import CardPermission from '@RolesScene/scenes/AddRole/components/CardsAccess/components/CardPermission';

// Utils
import { useStore } from '@Utils/context';
import { compose } from '@Utils';

// Styles
import useStyles from './styles';

const CardsAccess = ({ handleBack, handleNext, activeStep }) => {
  const {
    RoleStore: { getCardPermissions, cardPermissions, selectedPermissions }
  } = useStore();
  const { t } = useTranslation();
  const classes = useStyles();
  const handleNextCallback = useCallback(() => handleNext(activeStep));

  useEffect(() => {
    if (!cardPermissions.length) {
      getCardPermissions();
    }
  }, []);

  return (
    <Fragment>
      <Grid item xs>
        {cardPermissions.map(application => (
          <CardPermission key={shortid.generate()} application={application} />
        ))}
      </Grid>
      <Grid item className={classes.buttonsWrapper}>
        <Button id="back-btn" onClick={handleBack} className={classes.button}>
          {t('common.back')}
        </Button>
        <Button
          id="next-btn"
          variant="contained"
          disabled={!selectedPermissions.length}
          color="secondary"
          onClick={handleNextCallback}
          className={classes.button}
        >
          {t('mwAdmin.roles.createRole')}
        </Button>
      </Grid>
    </Fragment>
  );
};

CardsAccess.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  activeStep: PropTypes.number
};

export default compose(observer)(CardsAccess);
