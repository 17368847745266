/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action, set } from 'mobx';

// Providers
import { RoleProvider, CareProtocolProvider } from '@Providers';

// Stores
import CommonStore from '@Stores/CommonStore';
import TermsAndConditionsStore from '@Stores/TermsAndConditionsStore';
import ValidationStore from '@Stores/ValidationStore';

//  Config
import { RolesForms as forms } from '@Assets/config/forms/roles';

class RoleStore extends ValidationStore {
  @observable errors = null;

  @observable role = {};

  @observable forms = forms;

  @observable error = null;

  @observable deleteRequestConfirmationIsOpen = false;

  @observable joiningOption = 'invitation';

  cardPermissions = observable.array([], { deep: true });

  filter = observable.array([], { deep: false });

  deleteRoleId = null;

  @observable selectedPermissions = [];

  @action('roleStore => _handleRequestError') _handleRequestError = err => {
    this.errors =
      (err.response && err.response.body && err.response.body.errors) || err;
    throw err;
  };

  // TODO Remove it after finishing all roles forms validation implementation
  @action setFormField(key, val) {
    this.role[key] = val;
  }

  @action('roleStore => setEditFormFields') setEditFormFields = () => {
    Object.keys(this.forms.editRole.fields).forEach(field => {
      this.forms.editRole.fields[field].value = this.role[field];
    });
  };

  baseRequestForRole = ({ id, getData }) => {
    CommonStore.setPending();
    return getData(id)
      .then(this.setRole)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  getRole = id =>
    this.baseRequestForRole({
      id,
      getData: CareProtocolProvider.getRole
    });

  @action('roleStore => handle response in getRole') setRole = response => {
    set(this.role, response);
  };

  // TODO: check code bellow when endpoint will be ready
  createRole = data => {
    const roleData = {
      name: this.forms.roleSettings.fields['role name'].value,
      joining_options: {
        enabled_registration_form: false,
        enabled_terms: TermsAndConditionsStore.isTermsRequired,
        is_public_access: this.joiningOption === 'invitationOrPublic'
      },
      resource_ids: this.selectedPermissions,
      ...data
    };

    CommonStore.setPending();
    return CareProtocolProvider.createRole(roleData)
      .then(
        action('roleStore => handle response in createRole', role => {
          this._resetSelectedPermissions();
          const {
            isTermsRequired,
            createTermsAndConditionsDraft
          } = TermsAndConditionsStore;
          if (isTermsRequired) {
            createTermsAndConditionsDraft(role.id, true);
          }
          this.resetCreationRoleData();
          return role;
        })
      )
      .catch(e => {
        if (e.status === 400) {
          CommonStore.setModalOptions({
            modalName: 'ModalError',
            modalProps: {
              text: e.response.body.errors.map(error => error.message)
            }
          });
        }
        this._handleRequestError(e);
      })
      .finally(CommonStore.clearPending);
  };

  @action('roleStore => updateRole') updateRole() {
    const role = { ...this.role, name: this.forms.editRole.fields.name.value };

    CommonStore.setPending();
    return RoleProvider.updateRole(role)
      .then(
        action('roleStore => handle response in updateRole', updatedRole => {
          this.clearEditRoleForm();
          this.setRole(updatedRole);
          return updatedRole;
        })
      )
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  }

  removeRole = (id = this.deleteRoleId) => {
    CommonStore.setPending();
    return RoleProvider.deleteRole(id)
      .then(
        action('roleStore => handle response in removeRole', () => {
          this.clearTemporaryId();
        })
      )
      .catch(
        action('roleStore => handle error in removeRole', err => {
          const error = err.response.body.error;
          this.error = error;

          CommonStore.setModalOptions({
            modalName: 'ModalErrorRemovingRole',
            modalProps: {
              haveErrors: !!error,
              clearErrorMessage: () => {
                this.clearErrorMessage();
                this.clearTemporaryId();
                CommonStore.delModalOptions();
              }
            }
          });

          throw err;
        })
      )
      .finally(CommonStore.clearPending);
  };

  @action('roleStore => handleRoleDeleteClick') handleRoleDeleteClick = (
    id,
    isOpen = true
  ) => {
    this.deleteRoleId = id;
    this.deleteRequestConfirmationIsOpen = isOpen;
  };

  @action('roleStore => closeActionDialog') closeActionDialog = () => {
    this.deleteRoleId = null;
    this.deleteRequestConfirmationIsOpen = false;
  };

  @action('roleStore => setDeleteRoleId') setDeleteRoleId = id => {
    this.deleteRoleId = id;
  };

  @action('roleStore => clearTemporaryId') clearTemporaryId() {
    this.deleteRoleId = null;
  }

  @action('roleStore => clearCreateRoleForm') clearCreateRoleForm = () => {
    this.forms.createRole.fields.name.value = '';
    this.forms.createRole.meta.isValid = false;
  };

  @action('roleStore => clearEditRoleForm') clearEditRoleForm() {
    this.forms.editRole.fields.name.value = '';
    this.forms.editRole.meta.isValid = true;
  }

  @action('roleStore => clearErrorMessage') clearErrorMessage() {
    this.error = null;
  }

  @action('roleStore => resetRole') resetRole = () => {
    this.role = {};
  };

  @action('roleStore => reset store') reset = () => {
    this.resetCreationRoleData();
  };

  getCardPermissions = () => {
    CommonStore.setPending();
    return CareProtocolProvider.getCards()
      .then(
        action(
          'roleStore => handle response in getCardPermissions',
          response => {
            this._setCardPermissions(response.content);
          }
        )
      )
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  @action('roleStore => _setCardPermissions')
  _setCardPermissions = cardPermissions => {
    this.cardPermissions.replace(cardPermissions);
  };

  @action('roleStore => setJoiningOption') setJoiningOption = value => {
    this.joiningOption = value;
  };

  @action('roleStore => _resetSelectedPermissions')
  _resetSelectedPermissions = () => {
    this.selectedPermissions = [];
  };

  @action('roleStore => checkAllPermissions') checkAllPermissions = (
    name,
    checked
  ) => {
    this.cardPermissions.forEach(application => {
      // Find correct permission area
      const area = application.contents.find(
        permissionArea => permissionArea.code === name
      );
      if (area) {
        area.checked = checked;
        // Check/uncheck all child permissions
        area.contents.forEach(permission => {
          const currentPermission = permission;
          currentPermission.checked = checked;

          // TODO: Simplify code below
          if (currentPermission) {
            this.selectedPermissions = this.selectedPermissions.filter(
              selectedPermission => selectedPermission !== permission.id
            );
            if (checked) {
              currentPermission.checked = true;
              this.selectedPermissions.push(currentPermission.id);
            } else {
              currentPermission.checked = false;
            }
          }
        });
      }
    });
  };

  @action('roleStore => checkPermission') checkPermission = (
    areaCode,
    permissionCode,
    checked
  ) => {
    this.cardPermissions.forEach(application => {
      const area = application.contents.find(
        permissionArea => permissionArea.code === areaCode
      );
      if (area) {
        const permission = area.contents.find(currentPermission =>
          currentPermission.code.includes(permissionCode)
        );
        if (permission) {
          if (checked) {
            permission.checked = true;
            this.selectedPermissions.push(permission.id);
          } else {
            permission.checked = false;
            this.selectedPermissions = this.selectedPermissions.filter(
              currentPermission => currentPermission !== permission.id
            );
          }
        }

        // Auto check or uncheck "All permissions" checkbox logic
        area.checked = area.contents.every(
          currentPermission => currentPermission.checked
        );
      }
    });
  };

  @action('RoleStore => resetCreationRoleData') resetCreationRoleData = () => {
    this._setCardPermissions([]);
    this.forms = forms;
    this.joiningOption = 'invitation';
    TermsAndConditionsStore.changeTermsRequirement(false);
  };
}

export default new RoleStore();
