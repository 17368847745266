/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { observer } from 'mobx-react-lite';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/context';

// Styles
const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: '60px'
  },
  editor: {
    flexGrow: 1,
    '& textarea': {
      width: '100%',
      height: '100%',
      marginBottom: '15px',
      background: '#F9F8FE',
      padding: '15px',
      resize: 'none',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize
    },
    '& textarea:focus': {
      outline: 'none'
    }
  },
  mobileViewer: {
    width: '332px',
    height: '644px',
    '& div': {
      backgroundImage: 'url(/public/img/T&Cs-screen.svg)',
      backgroundRepeat: 'no-repeat',
      height: '100%',
      padding: '101px 23px 77px 21px'
    },
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      height: '100%',
      maxWidth: '100%',
      overflowX: 'hidden',
      overflow: 'scroll',
      margin: 0,
      padding: '8px 10px 0 10px',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize
    }
  }
}));

export const Editor = () => {
  const classes = useStyles();
  const {
    TermsAndConditionsStore: { updateTermsAndConditionsText, draftText }
  } = useStore();

  return (
    <Grid container className={classes.wrapper} spacing={2}>
      <Grid item className={classes.editor}>
        <textarea
          id="editor-input"
          value={draftText}
          onChange={e => updateTermsAndConditionsText(e.target.value)}
          placeholder="Please add text of Terms and Conditions for new role here..."
          autoFocus
        />
      </Grid>
      <Grid item className={classes.mobileViewer}>
        <div>
          <pre id="output-content">{draftText}</pre>
        </div>
      </Grid>
    </Grid>
  );
};

export default compose(observer)(Editor);
