/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useCallback } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';

// Material UI
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from '@material-ui/core';

// Components
import { Checkbox } from '@CommonScene/index';
import RolesPermission from '@RolesScene/scenes/AddRole/components/CardsAccess/components/RolesPermission';

// Store
import { useStore } from '@Utils/context';

// Styles
import useStyles from './styles';

const RolesPermissionWrapper = ({ permissionArea }) => {
  const {
    RoleStore: { checkAllPermissions, checkPermission }
  } = useStore();
  const classes = useStyles();

  const handleChange = (e, areaCode, permissionCode) => {
    const { checked } = e.target;
    checkPermission(areaCode, permissionCode, checked);
  };

  const selectAll = e => {
    const { name, checked } = e.target;
    checkAllPermissions(name, checked);
  };

  const stopPropagation = useCallback(e => e.stopPropagation());

  return (
    <ExpansionPanel
      className={classes.expansionRoot}
      key={shortid.generate()}
      expanded
    >
      <ExpansionPanelSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        classes={{
          root: classes.expansionHeadingRoot,
          content: classes.expansionContent
        }}
        onClick={stopPropagation}
      >
        <Typography className={classes.heading}>
          {permissionArea.name}
        </Typography>
        <Checkbox
          onChange={selectAll}
          name={permissionArea.code}
          checked={permissionArea.checked}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.permissionsBlock}>
        {permissionArea.contents.map(permission => (
          <RolesPermission
            key={shortid.generate()}
            label={permission.name}
            name={permission.name}
            handleChange={e =>
              handleChange(e, permissionArea.code, permission.code)
            }
            checked={permission.checked}
          />
        ))}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

RolesPermissionWrapper.propTypes = {
  permissionArea: PropTypes.object.isRequired
};

export default RolesPermissionWrapper;
