/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// core
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// Material UI
import { Grid, Button, withStyles } from '@material-ui/core';
import IconEdit from '@material-ui/icons/Edit';

// Utils
import { compose } from '@Utils';
import { translation } from '@Utils/translation';
import { editableRoleTypes } from '@Utils/constans';

// Components
import { StaticTextField } from '@CommonScene';

// Styles
import classNames from 'classnames';
import styles from './styles';

const RoleDetails = ({
  t,
  classes,
  roleId,
  handleDeleteClick,
  inviteUserToRole,
  role
}) => (
  <Grid container className={classes.wrapper}>
    <Grid item xs={6}>
      <StaticTextField label={t('mwAdmin.roles.roleName')}>
        {translation(role.name, 'rolesNames')}
      </StaticTextField>
      <StaticTextField label={t('mwAdmin.roles.joiningOptions')}>
        {role.is_public_access
          ? t('mwAdmin.roles.publicAccess')
          : t('mwAdmin.roles.invitationOnly')}
      </StaticTextField>
    </Grid>
    <Grid item xs={6}>
      <div className={classes.btnWrap}>
        <Button variant="outlined" color="default" onClick={inviteUserToRole}>
          {t('mwAdmin.roles.inviteUserToRole')}
        </Button>
      </div>
      {editableRoleTypes.includes(role.type) ? (
        <Fragment>
          <div className={classes.btnWrap}>
            <Button
              variant="contained"
              size="small"
              className={classNames(classes.padding, classes.transparentBtn)}
              component={Link}
              to={`/roles/${roleId}/edit`}
            >
              <IconEdit className={classes.editIcon} />
              {t('common.edit')}
            </Button>
          </div>
          <div className={classes.btnWrap}>
            <Button
              variant="outlined"
              color="default"
              className={classNames(classes.padding, classes.removeBtn)}
              onClick={handleDeleteClick}
            >
              {t('common.remove')}
            </Button>
          </div>
        </Fragment>
      ) : null}
    </Grid>
  </Grid>
);

RoleDetails.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  roleId: PropTypes.string.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  inviteUserToRole: PropTypes.func.isRequired,
  role: PropTypes.object
};

RoleDetails.defaultProps = {
  isDialogOpen: false,
  role: {}
};

export default compose(
  withTranslation(),
  withStyles(styles)
)(RoleDetails);
