/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

import { toJS, action } from 'mobx';
import Validator from 'validatorjs';

class ValidationStore {
  constructor() {
    // Custom rules
    Validator.register(
      'notNull',
      inputValue => +inputValue > 0,
      'The :attribute must be greater then 0.'
    );
  }

  getFlattenedValues = (formName, valueKey = 'value') => {
    const data = {};
    const form = toJS(this.forms[formName]).fields;
    Object.keys(form).map(key => {
      data[key] = form[key][valueKey];
      return null;
    });
    return data;
  };

  @action onFieldChange = (field, value, formName) => {
    let parsedValue = value;
    if (field === 'login' || field === 'phone') {
      parsedValue = value.replace(/\D+/g, '');
    }
    if (field === 'name' || field === 'surname') {
      parsedValue = value.slice(0, 40);
    }

    // if (field === 'role' && this.setAdminGroup) {
    //   if (this.clearFields) {
    //     this.clearFields();
    //   }
    //   if (value) {
    //     this.setAdminGroup(value);
    //   }
    // }

    this.forms[formName].fields[field].value = parsedValue;
    const validation = new Validator(
      this.getFlattenedValues(formName, 'value'),
      this.getFlattenedValues(formName, 'rule'),
      {
        'required.passwordConfirm': 'The Confirm Password field is required.',
        'same.passwordConfirm':
          'The Password and Confirm Password fields must match.'
      }
    );

    validation.fails();
    this.forms[formName].meta.isValid = validation.passes();
    this.forms[formName].fields[field].error =
      validation.errors.first(field) || null;
  };
}

export default ValidationStore;
