/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Material-UI imports
import { withStyles } from '@material-ui/core/styles';
import {
  Divider,
  Grid,
  Typography,
  Button,
  Paper,
  CardMedia
} from '@material-ui/core';

// Components
import SnackBar from '@AuthScene/scenes/common/SnackBar';
import { PhoneNumberField, PasswordField } from '@CommonScene';

// Component Style imports
import styles from './styles';

// Utils
import { compose } from '@Utils';

export const LoginForm = ({
  classes,
  onFieldChange,
  clearErrorMessage,
  handleChangeForm,
  handleSubmitForm,
  formData,
  errors,
  t,
  isSubmitBtnDisabled
}) => (
  <div className={classes.root}>
    <Grid
      container
      className={classes.offset}
      justify="center"
      alignItems="center"
      spacing={0}
    >
      <Grid item className={classes.containerWithImage}>
        <Paper elevation={6} className={classes.card}>
          <CardMedia
            className={classes.media}
            image="/public/img/Login.png"
            title="Login to Master Wallet"
          />
        </Paper>
      </Grid>
      <Grid item className={classes.container}>
        <Paper elevation={3} className={classes.paper}>
          <form
            className={classes.form}
            onSubmit={handleSubmitForm}
            id="login-form"
          >
            <Typography
              id="login-form-title"
              align="left"
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.headerTitle}
            >
              {t('auth.login.loginHeading')}
            </Typography>
            <Divider className={classes.headerBottomBorder} />
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item md sm xs>
                <PhoneNumberField
                  name="login"
                  label={t('auth.login.loginLabel')}
                  placeholder={t('auth.login.loginPlaceholder')}
                  error={formData.fields.login.error}
                  value={formData.fields.login.value}
                  onChange={e =>
                    onFieldChange(e.target.name, e.target.value, 'signInForm')
                  }
                  id="login-input"
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item md sm xs>
                <PasswordField
                  name="password"
                  inputProps={{ maxLength: 40 }}
                  label={t('auth.login.passwordLabel')}
                  placeholder={t('auth.login.passwordPlaceholder')}
                  value={formData.fields.password.value}
                  error={formData.fields.password.error}
                  onChange={e =>
                    onFieldChange(e.target.name, e.target.value, 'signInForm')
                  }
                  id="password-input"
                />
              </Grid>
            </Grid>

            {errors ? (
              <SnackBar
                id="error-block"
                onClose={clearErrorMessage}
                variant={'error'}
                message={errors}
              />
            ) : null}

            <Grid container>
              <Grid item align="left" xs={6}>
                <Button
                  disableFocusRipple
                  id="reset-password-btn"
                  onClick={handleChangeForm}
                  className={classes.forgotButton}
                  variant="text"
                  color="primary"
                >
                  {t('auth.login.remindPassword')}
                </Button>
              </Grid>
              <Grid item align="right" xs={6}>
                <Button
                  type="submit"
                  color="primary"
                  id="login-btn"
                  className={classes.button}
                  variant="contained"
                  disabled={!formData.meta.isValid || isSubmitBtnDisabled}
                >
                  {t('auth.login.loginButton')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  </div>
);

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  clearErrorMessage: PropTypes.func.isRequired,
  handleChangeForm: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  errors: PropTypes.string,
  isSubmitBtnDisabled: PropTypes.bool,
  t: PropTypes.func.isRequired
};

LoginForm.defaultProps = {
  errors: undefined,
  isSubmitBtnDisabled: false
};

export default compose(
  withStyles(styles),
  withTranslation(),
  observer
)(LoginForm);
