/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material UI
import { AppBar, Toolbar, Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

// Components
import LoggedInPanel from '@CommonScene/header/LoggedInPanel';
import LogOutMenu from '@CommonScene/LogOutMenu';

// Styles
import styles from './styles';

// Utils
import { compose } from '@Utils';

export class Header extends React.Component {
  static propTypes = {
    CommonStore: PropTypes.object,
    AuthStore: PropTypes.object,
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    anchorEl: null
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    const { AuthStore } = this.props;

    this.handleMenuClose();
    AuthStore.logout();
  };

  render() {
    const { anchorEl } = this.state;
    const {
      classes,
      CommonStore: { pending, isACN },
      t
    } = this.props;
    const isMenuOpen = Boolean(anchorEl);

    return (
      <Grid item xs={12} className={classes.headerWrapper}>
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar className={classes.toolbarWrapper}>
              <Typography
                className={classes.title}
                variant="h6"
                color="inherit"
                noWrap
              >
                {isACN ? (
                  <img
                    id="logo-img"
                    src="/public/img/ACN_logo.png"
                    className={classes.image}
                    alt="Solve Care"
                  />
                ) : null}
              </Typography>
              <span className={classes.logoDescription}>
                {t('header.slogan')}
              </span>
              <div className={classes.grow} />
              <LoggedInPanel
                isMenuOpen={isMenuOpen}
                handleMobileMenuOpen={this.handleMobileMenuOpen}
                handleProfileMenuOpen={this.handleProfileMenuOpen}
              />
            </Toolbar>
          </AppBar>
          <LogOutMenu
            anchorEl={anchorEl}
            isMenuOpen={isMenuOpen}
            handleMenuClose={this.handleMenuClose}
            handleLogout={this.handleLogout}
          />
        </div>
        {pending ? (
          <LinearProgress
            id="header-loader"
            className={classes.loader}
            variant="query"
          />
        ) : null}
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  inject('AuthStore', 'CommonStore'),
  observer
)(Header);
