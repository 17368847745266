/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Permissions names
export const VIEW_USER_ROLES =
  'care-protocol-service.user_roles.view_user_roles';
export const CREATE_USER_ROLES =
  'care-protocol-service.user_roles.create_user_role';
export const USER_ROLES = 'care-protocol-service.user_roles';
export const VIEW_ADMIN_ROLES =
  'care-protocol-service.admin_roles.view_admin_roles';
export const CREATE_ADMIN_ROLES = '8';
export const ADMIN_ROLES = '9';
export const VIEW_INVITATIONS =
  'network-participant-service.invitations.view_invitations';
export const RESEND_INVITATIONS =
  'network-participant-service.invitations.resend_invitation';
export const CREATE_INVITATIONS =
  'network-participant-service.invitations.create_invitation';
export const CANCEL_INVITATIONS =
  'network-participant-service.invitations.cancel_invitation';
export const INVITATIONS = 'network-participant-service.invitations';
