/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import i18n from 'i18next';

// Store
import MembersPaymentsStore from '@Stores/MembersPaymentsStore';

// Utils
import { addCommasInNumber, hideFieldsInfo } from '@Utils/formatting';

export const handleRowClick = (event, id, redirectTo) => {
  event.stopPropagation();

  MembersPaymentsStore.updateMemberId(id);
  redirectTo(`/payments/members/${hideFieldsInfo(id)}`);
};

export const sortAggregatedData = orderBy => () =>
  MembersPaymentsStore.sortAggregatedData({ orderBy });

export const tableConfig = {
  defaultOrder: 'desc',
  defaultOrderBy: 'owner',
  handleRowClick,
  rowIdKey: 'owner_id',
  headings: [
    {
      id: 'owner',
      title: i18n.t('can.members.table.memberId'),
      sortable: true,
      cb: sortAggregatedData('owner')
    },
    {
      id: 'transactions_count',
      title: i18n.t('can.members.table.transactions'),
      sortable: true,
      cb: sortAggregatedData('transactions_count')
    },
    {
      id: 'fiat_amount',
      title: i18n.t('can.members.table.usdAmount'),
      sortable: true,
      cb: sortAggregatedData('fiat_amount')
    },
    {
      id: 'solve_amount',
      title: i18n.t('can.members.table.solveAmount'),
      sortable: true,
      cb: sortAggregatedData('solve_amount')
    }
  ],
  rowCells: [
    { key: 'owner', cb: hideFieldsInfo },
    'transactions_count',
    { key: 'fiat_amount', cb: addCommasInNumber },
    { key: 'solve_amount', cb: addCommasInNumber }
  ]
};
