/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Switch,
  FormControl,
  FormGroup,
  FormControlLabel,
  Button,
  Typography
} from '@material-ui/core';

// Components
import Editor from '@RolesScene/scenes/common/TermsAndConditions/Editor';

// Utils
import { useStore } from '@Utils/context';
import { compose } from '@Utils';

// Styles
const useStyles = makeStyles(theme => ({
  mainWrapper: {
    flexGrow: 1
  },
  termsWrapper: {
    width: '290px',
    '& p': {
      color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  termsSwitcher: {
    '& label': {
      margin: 0
    }
  },
  buttonsWrapper: {
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1)
    }
  },
  editorWrapper: {
    flexGrow: 1,
    marginTop: '20px'
  }
}));

export const TermsAndConditions = ({ handleNext, handleBack, activeStep }) => {
  const classes = useStyles();

  const {
    TermsAndConditionsStore: {
      draftText,
      clearTermsAndConditionsText,
      isTermsRequired,
      changeTermsRequirement
    }
  } = useStore();

  useEffect(() => {
    if (!isTermsRequired && draftText) {
      clearTermsAndConditionsText();
    }
  }, [isTermsRequired]);

  return (
    <Fragment>
      <Grid container direction="column" className={classes.mainWrapper}>
        <div className={classes.termsWrapper}>
          <FormControl component="fieldset" className={classes.termsSwitcher}>
            <FormGroup row>
              <FormControlLabel
                id="terms-switcher-control"
                control={
                  <Switch
                    onChange={(event, value) => {
                      changeTermsRequirement(value);
                    }}
                    checked={isTermsRequired}
                  />
                }
                label="Terms and Conditions required"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
          <Typography variant="body2" id="hint">
            Defines if Terms and Conditions needed to be accepted when users
            join this role
          </Typography>
        </div>
        {isTermsRequired ? (
          <Grid container direction="column" className={classes.editorWrapper}>
            <Editor id="editor" />
          </Grid>
        ) : null}
      </Grid>
      <Grid item className={classes.buttonsWrapper}>
        {activeStep > 0 ? (
          <Button id="back-btn" onClick={handleBack}>
            Back
          </Button>
        ) : null}
        <Button
          id="next-btn"
          variant="contained"
          disabled={isTermsRequired && !draftText}
          color="secondary"
          onClick={() => handleNext(activeStep)}
        >
          Next
        </Button>
      </Grid>
    </Fragment>
  );
};

TermsAndConditions.propTypes = {
  steps: PropTypes.array.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  activeStep: PropTypes.number,
  isTermsRequired: PropTypes.bool,
  changeTermsRequirement: PropTypes.func.isRequired
};

export default compose(observer)(TermsAndConditions);
