/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import shortid from 'shortid';
import i18n from 'i18next';

// Utils
import { formatDate, formatPhoneNumber } from '@Utils/formatting';
import { RESEND_INVITATIONS } from '@Utils/constans/permissions';
import { translation } from '@Utils/translation';

// Material UI
import { Refresh, Person } from '@material-ui/icons';

// Stores
import InvitationStore from '@Stores/InvitationStore';
import CommonStore from '@Stores/CommonStore';
import AuthStore from '@Stores/AuthStore';

export const renderRolesNames = key => translation(key, 'rolesNames');

export const renderRolesNamesForACN = roles =>
  roles.map((key, i) => {
    const divider = i === roles.length - 1 ? '' : '; ';
    return (
      <React.Fragment key={shortid.generate()}>
        {`${translation(key, 'rolesNames')}${divider}`}
        <br />
      </React.Fragment>
    );
  });

export const chooseConfigForRolesNames = () => {
  if (CommonStore.isACN) {
    return { key: 'role_name_list', cb: renderRolesNamesForACN };
  }
  return { key: 'role', cb: renderRolesNames };
};

export const handleResendCode = ({ rowId }) => {
  InvitationStore.resendInvitation(rowId);
};

export const handleViewProfile = ({ rowId, redirectTo }) => {
  redirectTo(`/networks/view/${rowId}`);
};

export const tableConfig = {
  defaultOrder: 'desc',
  defaultOrderBy: 'creationDate',
  hasActions: true,
  rowIdKey: 'id',
  extraIdKey: 'owner_id',
  headings: [
    {
      id: 'accepted_at',
      title: 'Accepted',
      sortable: true,
      cb: InvitationStore.setOrder
    },
    {
      id: 'expired_at',
      title: 'Expired',
      sortable: true,
      cb: InvitationStore.setOrder
    },
    {
      id: 'revoked_at',
      title: 'Revoked',
      sortable: true,
      cb: InvitationStore.setOrder
    },
    i18n.t('common.cellPhone'),
    'Role'
  ],
  rowCells: [
    { key: 'accepted_at', cb: formatDate },
    { key: 'expired_at', cb: formatDate },
    { key: 'revoked_at', cb: formatDate },
    { key: 'phone', cb: formatPhoneNumber },
    chooseConfigForRolesNames()
  ],
  actionsConfig: {
    filterMenuItems: exclude => {
      const excludedValue = 'INVITATION_ACCEPTED';
      const { permissions } = AuthStore;

      if (
        exclude === excludedValue ||
        !permissions.includes(RESEND_INVITATIONS)
      ) {
        // TODO: Fix it. Use correct 'this' context (this.actionsConfig.menuItems)
        return tableConfig.actionsConfig.menuItems.filter(
          item => item.status !== excludedValue
        );
      }
      return tableConfig.actionsConfig.menuItems;
    },
    filterKey: 'status',
    menuItems: [
      {
        name: i18n.t('common.resendCode'),
        icon: <Refresh />,
        status: 'INVITATION_ACCEPTED',
        handleClick: handleResendCode
      },
      {
        name: i18n.t('common.viewInviteeProfile'),
        icon: <Person />,
        isRedirect: true,
        handleClick: handleViewProfile
      }
    ]
  }
};
