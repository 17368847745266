/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// React imports
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

// Material-UI imports
import { withStyles } from '@material-ui/core/styles';
import { InputAdornment } from '@material-ui/core';
import { TextField } from '@CommonScene';
import SearchIcon from '@material-ui/icons/Search';

// Components
import CancelSearchBtn from '@CommonScene/CancelSearchBtn';

// Component Style imports
import styles from './styles';

const TableSearch = ({
  classes,
  placeholder,
  value,
  setQuery,
  setDefaultStatuses
}) => (
  <div className={classes.root}>
    <TextField
      className={classes.searchField}
      id="search_query"
      placeholder={placeholder}
      value={value}
      onChange={e => setQuery(e.target.value)}
      InputProps={{
        classes: {
          input: classes.searchInput,
          underline: classes.overvrideUnderline
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        )
      }}
    />
    <CancelSearchBtn value={value} setDefaultStatuses={setDefaultStatuses} />
  </div>
);

TableSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
  setDefaultStatuses: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string
};

TableSearch.defaultProps = {
  placeholder: 'Search',
  setDefaultStatuses: () => {
    // Stub function
  }
};

export default withStyles(styles)(observer(TableSearch));
