/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Material UI
import { InputAdornment, IconButton, withStyles } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';

// Components
import { TextField } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { validationKeys } from '@Utils/constans';

// Styles
import styles from './styles';

export class PasswordField extends Component {
  static propTypes = {
    classes: PropTypes.object,
    onChange: PropTypes.func,
    error: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    onChange: () => {
      // Stub function
    },
    error: null,
    placeholder: 'Enter your password',
    label: 'Your Password'
  };

  state = {
    showPassword: false
  };

  togglePasswordVisability = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { label, error, classes, onChange, t, ...other } = this.props;
    const { showPassword } = this.state;
    // fix for 'React does not recognize the `tReady` prop on a DOM element...'
    const restProps = Object.assign({}, other);
    delete restProps.tReady;

    return (
      <TextField
        error={!!error}
        label={label}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        helperText={t(validationKeys[error])}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                id="icon-button"
                aria-label="Toggle password visibility"
                className={classes.buttonVisibility}
                onClick={() => this.togglePasswordVisability()}
              >
                <Visibility
                  id="toggle-visibility-icon"
                  className={!showPassword ? classes.iconBlack : null}
                />
              </IconButton>
            </InputAdornment>
          )
        }}
        {...restProps}
      />
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  observer
)(PasswordField);
