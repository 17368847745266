/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

export default requests => ({
  getCards: params =>
    requests.get('/v1/resources/aggregatedContainers', params),
  createRole: params => requests.post('/v2/userRoles', params),
  getAdminRoles: params => requests.get('/v1/adminRoles', params),
  getRoles: params => requests.get('/v1/userRoles', params),
  getAdminRole: roleId => requests.get(`/v1/adminRoles/${roleId}`),
  getRole: roleId => requests.get(`/v1/userRoles/${roleId}`),
  getTermsAndConditionsList: ({ roleId, ...params }) =>
    requests.get(`/v1/userRoles/${roleId}/terms`, params),
  getTermsAndConditionsItem: termId => requests.get(`/v1/terms/${termId}`),
  createTermsAndConditionsDraft: params => requests.post('/v1/terms', params),
  linkTermsAndConditionsDraftToRole: (roleId, draftId) =>
    requests.post(`/v1/userRoles/${roleId}/terms/${draftId}`),
  publishTermsAndConditions: (roleId, draftId) =>
    requests.post(`/v1/terms/${draftId}?roleId=${roleId}`),
  deleteTermsAndConditionsDraft: termsId => requests.del(`/v1/terms/${termsId}`)
});
