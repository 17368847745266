/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Material UI
import { Grid, Stepper, Step, StepLabel, withStyles } from '@material-ui/core';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class HorizontalStepper extends Component {
  state = {
    activeStep: 0
  };

  static propTypes = {
    steps: PropTypes.array,
    classes: PropTypes.object.isRequired
  };

  static defaultProps = {
    steps: []
  };

  setActiveStep = value => {
    this.setState({ activeStep: value });
  };

  componentSteps = this.props.steps.map(step => step.title);

  handleNext = step => {
    if (this.props.steps[step].nextHandler) {
      this.props.steps[step].nextHandler();
    }
    if (this.props.steps.length - 1 !== step) {
      this.setActiveStep(this.state.activeStep + 1);
    }
  };

  handleBack = () => {
    if (this.state.activeStep > 0) {
      this.setActiveStep(this.state.activeStep - 1);
    }
  };

  getStepContent = step => {
    return React.cloneElement(this.props.steps[step].component, {
      handleNext: this.handleNext,
      handleBack: this.handleBack,
      activeStep: this.state.activeStep
    });
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;
    return (
      <div className={classes.root}>
        <Stepper
          activeStep={activeStep}
          classes={{
            root: classes.stepperRoot
          }}
        >
          {this.componentSteps.map(label => {
            return (
              <Step key={shortid.generate()}>
                <StepLabel
                  classes={{
                    label: classes.stepLabel
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.step,
                      completed: classes.stepCompleted,
                      active: classes.stepActive,
                      disabled: classes.stepDisabled
                    }
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Grid
          container
          direction="column"
          justify="space-between"
          className={classes.stepContent}
        >
          {this.getStepContent(activeStep)}
        </Grid>
      </div>
    );
  }
}

export default compose(withStyles(styles))(HorizontalStepper);
