/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

// Components
import RoleList from '@RolesScene/scenes/RolesList/container';

// Utils
import { useStore } from '@Utils/context';
import { userRolesTableConfig } from '@Assets/config/tables/roles';

// Constants
import { CREATE_USER_ROLES } from '@Utils/constans/permissions';

const UserRolesList = () => {
  const { UserRolesStore, AuthStore } = useStore();
  const { t } = useTranslation();
  const { permissions } = AuthStore;

  const getHeaderOptions = () => {
    const headerOptions = {
      title: t('mwAdmin.sidebar.userRoles'),
      placeholder: t('mwAdmin.roles.searchByRole'),
      isList: true
    };

    if (permissions.includes(CREATE_USER_ROLES)) {
      return {
        ...headerOptions,
        addButtonTitle: t('mwAdmin.roles.addRole'),
        addButtonLink: '/roles/create'
      };
    }

    return headerOptions;
  };

  return (
    <RoleList
      headerOptions={getHeaderOptions()}
      RolesStore={UserRolesStore}
      tableConfig={userRolesTableConfig}
    />
  );
};

export default observer(UserRolesList);
