/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import { MainContentWrapper, HorizontalStepper } from '@CommonScene';
import RoleSettings from '@RolesScene/scenes/AddRole/components/RoleSettings';
import TermsAndConditions from '@RolesScene/scenes/AddRole/components/TermsAndConditions';
import CardsAccess from '@RolesScene/scenes/AddRole/components/CardsAccess/container';

// Utils
import { compose } from '@Utils';

class AddRole extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    InvitationStore: PropTypes.object.isRequired,
    RoleStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  getHeaderOptions() {
    const { t } = this.props;

    return {
      title: t('mwAdmin.roles.addRole')
    };
  }

  render() {
    const {
      RoleStore: { createRole },
      history
    } = this.props;

    return (
      <MainContentWrapper headerOptions={this.getHeaderOptions()}>
        <HorizontalStepper
          steps={[
            {
              title: 'Role Settings',
              component: <RoleSettings goBack={() => history.goBack()} />
            },
            {
              title: 'Terms and Conditions',
              optional: true,
              component: <TermsAndConditions />
            },
            {
              title: 'Card Access',
              component: <CardsAccess />,
              nextHandler: () => {
                createRole().then(() => history.push('/user-roles'));
              }
            }
          ]}
        />
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject('RoleStore', 'InvitationStore'),
  withRouter,
  observer
)(AddRole);
