/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Styles
import { makeStyles } from '@material-ui/core/styles';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& fieldset': {
      margin: '0 0 0 10px'
    }
  },
  formControl: {
    margin: theme.spacing(3),
    '& > div': {
      margin: 0,
      '& > div:not(:first-child)': {
        marginTop: '10px'
      }
    }
  },
  group: {
    margin: theme.spacing(1, 0)
  },
  hint: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

const RadioButtons = ({ buttons, handleChange, activeValue }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup
          className={classes.group}
          value={activeValue}
          onChange={handleChange}
        >
          {buttons.map(button => {
            return (
              <Grid container direction="column" key={shortid.generate()}>
                <FormControlLabel
                  value={button.value}
                  control={<Radio />}
                  label={button.label}
                />
                {button.hint ? (
                  <Typography variant="caption" className={classes.hint}>
                    {button.hint}
                  </Typography>
                ) : null}
              </Grid>
            );
          })}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

RadioButtons.propTypes = {
  activeValue: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  buttons: PropTypes.array.isRequired
};

export default RadioButtons;
