/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { computed, observable, action, toJS, set } from 'mobx';

// Providers
import {
  GroupProvider,
  RoleProvider,
  InvitationProvider,
  DeckProvider,
  NpsProvider
} from '@Providers';

// Stores
import CommonStore from '@Stores/CommonStore';
import NetworkStore from '@Stores/NetworkStore';
import ValidationStore from '@Stores/ValidationStore';

// Utils
import { InvitationsForms as forms } from '@Assets/config/configForInvitationsForms';
import uniqBy from 'lodash/uniqBy';
import isArray from 'lodash/isArray';
import debounce from 'lodash/debounce';
import { DELAY_REQUEST } from '@Utils/constans';

class InvitationStore extends ValidationStore {
  invitations = observable.array([], { deep: false });

  filter = observable.array([], { deep: false });

  @observable errors = null;

  @observable query = '';

  @observable forms = forms;

  @observable order = 'desc';

  @observable orderBy = 'creationDate';

  @observable page = 0;

  @observable rowsPerPage = 25;

  @observable lastPage = false;

  searchFilter = observable.array([], { deep: false });

  @observable totalItems = 0;

  filteredRolesTemplates = observable.array([], { deep: false });

  roleTemplates = observable.array([], { deep: false });

  customFields = observable.array([], { deep: false });

  filteredSuggestions = observable.array([], { deep: false });

  fields = observable.array([], { deep: false });

  @observable adminGroup = null;

  decks = observable.array([], { deep: false });

  @observable isFieldsList = false;

  @observable isNPI = false;

  @observable financialGroup = {};

  @observable customQuery = {};

  @action('InvitationStore => _handleRequestError')
  _handleRequestError = err => {
    this.errors =
      (err.response && err.response.body && err.response.body.errors) || err;
    throw err;
  };

  @computed get filteredInvitations() {
    const invitations = toJS(this.invitations);

    return this.filter.length
      ? invitations.filter(invitation => this.filter.includes(invitation.id))
      : invitations;
  }

  // Not usable for now. Just for Sonar validation
  set filteredInvitations(value) {
    this.invitations = value;
  }

  @action('InvitationStore => setPageParams') setPageParams(
    lastPage,
    totalItems
  ) {
    this.lastPage = lastPage;
    this.totalItems = totalItems;
  }

  getInvitations = (pageNumber = this.page, pageSize = this.rowsPerPage) => {
    CommonStore.setPending();
    let queryS = {
      page: pageNumber,
      size: pageSize
    };

    if (this.searchFilter.length) {
      queryS = { ...queryS, roleTemplateId: this.searchFilter };
    }
    if (this.query) {
      queryS = { ...queryS, phoneNumber: this.query.replace(/[^0-9+]/g, '') };
    }

    return InvitationProvider.getInvitations(queryS)
      .then(response => {
        this.setInvitations(response.content, response.total_elements);
        this.setPageParams(response.last, response.total_elements);
      })
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  handleRoleSelect = typeId => {
    const selectedRoles = [...this.searchFilter];
    const matchId = selectedRoles.indexOf(typeId);

    if (matchId >= 0) {
      selectedRoles.splice(matchId, 1);
    } else {
      selectedRoles.push(typeId);
    }
    this._updateSearchFilter(selectedRoles);
  };

  @action('InvitationStore => _updateSearchFilter')
  _updateSearchFilter = filter => {
    this.searchFilter.replace(filter);
  };

  @action('InvitationStore => setQuery') setQuery = query => {
    this.resetPage();
    this._updateSearchFilter([]);
    this.query = query;
    this.search();
  };

  @action('InvitationStore => clearQuery') clearQuery = () => {
    this.query = '';
    this._updateSearchFilter([]);
  };

  @action('InvitationStore => _resetOrder') _resetOrder = () => {
    this.order = 'desc';
  };

  reset = () => {
    this.clearQuery();
    this._resetOrder();
  };

  search = debounce(this.getInvitations, DELAY_REQUEST);

  @action('InvitationStore => _resetOrder') setOrder = () => {
    if (this.order === 'asc') {
      this.order = 'desc';
    } else {
      this.order = 'asc';
    }
    return this.updateInvitations();
  };

  @action('InvitationStore => updateInvitations') updateInvitations = () => {
    this.resetPage();
    this.invitations.replace([]);
    return this.getInvitations();
  };

  fetchRoleTemplates = () => {
    CommonStore.setPending();
    return RoleProvider.getRoles({ size: 3000 })
      .then(this._setRoleTemplates)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  @action('InvitationStore => _setRoleTemplates')
  _setRoleTemplates = response => {
    this.roleTemplates.replace(response.content);
  };

  fetchFilteredRoles = () => {
    CommonStore.setPending();
    this._setFilteredRoleTemplates({ content: [] });
    return RoleProvider.getRoles({ size: 3000 })
      .then(this._setFilteredRoleTemplates)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  @action('InvitationStore => _setFilteredRoleTemplates')
  _setFilteredRoleTemplates = response => {
    this.filteredRolesTemplates.replace(response.content);
  };

  getAllGroups = ({ size = 10, searchStr }) => {
    CommonStore.setPending();
    return GroupProvider.getGroups({ searchStr, size, ...this.customQuery })
      .then(this._updateFilteredSuggestions)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  @action('InvitationStore => _updateFilteredSuggestions')
  _updateFilteredSuggestions = response => {
    this.setGroups(response.content);
    const filt = response.content
      .filter(field => field.custom.NPI || field.custom.TIN)
      .map(field => ({
        id: field.id,
        TIN: field.custom.TIN
          ? {
              name: field.custom.TIN_NAME
                ? field.custom.TIN_NAME.value
                : field.name,
              value: field.custom.TIN.value
            }
          : null,
        NPI: field.custom.NPI
          ? {
              value: field.custom.NPI.value,
              name: field.name
            }
          : null,
        // used in downshift pkg
        value: field.custom.NPI
          ? field.custom.NPI.value
          : field.custom.TIN.value,
        name: field.name,
        type: field.custom.NPI ? 'NPI' : 'TIN'
      }));

    this.filteredSuggestions.replace(filt);
    CommonStore.updateModalProps({ filteredSuggestion: filt });
  };

  getAllGroupsWithDebounce = debounce(this.getAllGroups, DELAY_REQUEST);

  @action('InvitationStore => setGroups') setGroups = customFields => {
    this.customFields.replace(customFields);
  };

  @action('InvitationStore => setInvitations') setInvitations = (
    invitations,
    total
  ) => {
    if (this.page === 0) {
      this.invitations.replace([]);
    }
    if (this.invitations.length === total) {
      return;
    }
    const newInvitations = [...this.invitations, ...invitations];

    this.invitations.replace(newInvitations);
  };

  @action('InvitationStore => handleFieldCreate') handleFieldCreate = field => {
    this.fields.push(field);
  };

  @action('InvitationStore => handleFieldRemove') handleFieldRemove = id => {
    this.fields.splice(id, 1);
  };

  @action('InvitationStore => handleFieldEdit') handleFieldEdit = ({
    id,
    field
  }) => {
    this.fields.splice(id, 1, field);
  };

  _getGroups = () => {
    return this.adminGroup === null
      ? uniqBy(toJS(this.fields), 'id')
          .map(field => field.id)
          .join(',')
      : this.adminGroup.map(field => field.id).join(',');
  };

  inviteUser = () => {
    const phone = `+${this.forms.inviteCreateForm.fields.phone.value}`;
    const roleId = this.forms.inviteCreateForm.fields.role.value;
    const groups = this._getGroups();

    CommonStore.setPending();

    // TODO: temporary condition value! Need to separate logic for different clients
    if (!CommonStore.isACN) {
      return InvitationProvider.createInvite({
        phone,
        roleId
      }).finally(CommonStore.clearPending());
    }

    return InvitationProvider.createUser(phone)
      .then(response => {
        const accountIds = [response.id];
        return InvitationProvider.inviteUser(groups, roleId, accountIds);
      })
      .then(response => {
        const id = response.content[0].users[0];
        return InvitationProvider.resendInvitation(id);
      })
      .catch(this._handleRequestError)
      .finally(() => {
        this.clearPageAndForms();
        CommonStore.clearPending();
      });
  };

  clearPageAndForms = () => {
    this.resetForms();
    this.clearFields();
    this.resetPage();
  };

  AddUserRoles = () => {
    const groups = this._getGroups();
    const roleId = NetworkStore.forms.inviteEditForm.fields.role.value;
    const currentUser = NetworkStore.currentUser;

    CommonStore.setPending();
    return InvitationProvider.inviteUser(groups, roleId, [currentUser.id])
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  resendInvitation = rowId => {
    CommonStore.setPending();
    const invitationItem = this.invitations.find(
      invitation => rowId === invitation.id
    );
    return NpsProvider.resendInvitation({
      phone: invitationItem.phone,
      role: invitationItem.role_id
    })
      .then(this.getInvitations)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  @action handleChangePage = page => {
    if (!this.lastPage) {
      this.page = page;
      this.getInvitations();
    }
  };

  @action('InvitationStore => setGroupCustomQuery')
  setGroupCustomQuery = templateDecks => {
    this.customQuery = {};
    const allowedDecks = this.decks.filter(deck =>
      templateDecks.includes(String(deck.id))
    );
    const decksLength = allowedDecks.length;

    const existInDeck = name => allowedDecks.some(deck => deck.name === name);

    const isNPIExist = existInDeck('NPI_PERFORMANCE');

    this.isNPI = isNPIExist;

    const isTINRewardsExist = existInDeck('TIN_REWARDS');

    const isCustomNPI = (() => {
      if (!isNPIExist) {
        return false;
      }
      return decksLength === 1 || (isTINRewardsExist && decksLength === 2);
    })();

    const isCustomTIN = !isNPIExist;

    if (isCustomNPI) {
      set(this.customQuery, { custom: 'NPI' });
    } else if (isCustomTIN) {
      set(this.customQuery, { custom: 'TIN', no_custom: 'NPI' });
    } else {
      this.customQuery = {};
    }
  };

  // TODO: Remove it and check all dependencies
  @action('InvitationStore => setAdminGroup') setAdminGroup = roleId => {
    const selectedTemplate = this.roleTemplates.find(
      template => template.id === roleId
    );
    const decks = Object.keys(selectedTemplate.permissions);
    this.setGroupCustomQuery(decks);
    if (decks.length === 1) {
      if (decks[0] === '1') {
        this.isFieldsList = false;
        this.adminGroup = [{ id: 1 }];
      } else if (
        decks[0] === '20' ||
        decks[0] === '21' ||
        decks[0] === '22' ||
        decks[0] === '23'
      ) {
        this.isFieldsList = false;
        this.adminGroup = [{ id: 4 }];
      } else if (decks[0] === '6') {
        this.isFieldsList = false;
        this.adminGroup = [{ id: this.financialGroup.id }];
      } else {
        this.isFieldsList = true;
        this.adminGroup = null;
      }
    } else {
      this.isFieldsList = true;
      this.adminGroup = null;
    }
  };

  getAllDecks = () => {
    CommonStore.setPending();
    return DeckProvider.getAllDecks()
      .then(this._getAllDecksHandler)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  _getAllDecksHandler = decks => {
    if (isArray(decks)) {
      this.setDecks(decks);
    }
    if (decks.content && isArray(decks.content)) {
      this.setDecks(decks.content);
    }
  };

  @action('InvitationStore => setDecks') setDecks(decks) {
    const newDecks = decks.map((deck, i) => ({
      ...deck,
      key: `deck-${i}`,
      checked: false
    }));
    this.decks.replace(newDecks);
  }

  @action('InvitationStore => resetForms') resetForms = () => {
    this.forms = forms;
    this.clearFields();
  };

  @action('InvitationStore => clearFields') clearFields = () => {
    this.fields.replace([]);
  };

  @action('InvitationStore => setPredefinedRole') setPredefinedRole(val) {
    this.forms.inviteCreateForm.fields.role.value = val;
  }

  @action('InvitationStore => resetPage') resetPage = () => {
    this.page = 0;
    this.rowsPerPage = 25;
  };

  getGroupByExternalId = externalId => {
    CommonStore.setPending();
    return GroupProvider.getGroupByExternalId(externalId)
      .then(this._setFinancialGroup)
      .catch(this._handleRequestError)
      .finally(CommonStore.clearPending);
  };

  @action('InvitationStore => _setFinancialGroup')
  _setFinancialGroup = response => {
    this.financialGroup = response;
  };
}

export default new InvitationStore();
