/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

// Components
import LoginForm from '@AuthScene/scenes/Login/components/LoginForm';

// Utils
import { compose } from '@Utils';
import { getParsedCookies } from '@Utils/formatting';
import {
  CAN_STATISTICS,
  PAYMENT_ADMIN,
  CAN_SPONSOR
} from '@Utils/constans/privateRoutes';

export class Login extends React.Component {
  static propTypes = {
    CommonStore: PropTypes.object.isRequired,
    AuthStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentWillUnmount() {
    this.props.AuthStore.clearInputFields('signInForm', ['login', 'password']);
  }

  handleChangeForm = () => {
    const {
      AuthStore: { handleChangeForm, clearErrorMessage },
      history
    } = this.props;

    clearErrorMessage();
    handleChangeForm(1);
    history.push('/reset-password');
  };

  handleSubmitForm = event => {
    event.preventDefault();
    const {
      AuthStore: { login },
      history
    } = this.props;

    return login().then(() => {
      const roles = getParsedCookies().roles;
      if (roles.includes(CAN_SPONSOR)) {
        history.replace('/deposits');
        return;
      }
      if (roles.includes(PAYMENT_ADMIN)) {
        history.replace('/wallets');
        return;
      }
      if (roles.includes(CAN_STATISTICS)) {
        history.replace('/statistics');
        return;
      }
      // history.replace('/networks');
      history.replace('/invitations');
    });
  };

  render() {
    const {
      CommonStore: { isBtnDisabled },
      AuthStore: { onFieldChange, clearErrorMessage, forms, errors }
    } = this.props;

    return (
      <LoginForm
        onFieldChange={onFieldChange}
        clearErrorMessage={clearErrorMessage}
        handleChangeForm={this.handleChangeForm}
        handleSubmitForm={this.handleSubmitForm}
        formData={toJS(forms.signInForm)}
        errors={errors}
        isSubmitBtnDisabled={isBtnDisabled}
      />
    );
  }
}

export default compose(
  inject('AuthStore', 'CommonStore'),
  withRouter,
  observer
)(Login);
